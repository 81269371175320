.mainContainer {
  background-color: #efefef;
  padding: 30px 75px;
}

.backBtn {
  display: flex;
  padding-bottom: 24px;
  cursor: pointer;
}

.leftIcon {
  margin: 2px;
  margin-right: 8px;
}

.backText {
  font-size: 14px;
  font-weight: 500;
}

/* all stories */
.container {
  display: flex;
  justify-content: space-between;
}

.storyContent1 {
  /* margin: 10px; */
  background-color: #ffffff;
  padding: 20px;
  flex-basis: 100%;
  padding-bottom: 40px;
}

.storyContent2 {
  background-color: #c9c5c5;
  flex-basis: 100%;
}

.profile {
  display: flex;
}

.profilePicture {
  width: 80px;
  height: 100px;
  object-fit: cover;
}

.profileDetails {
  margin-left: 12px;
  margin-top: 15px;
}

.employeeName {
  font-size: 24px;
  font-weight: 700;
}

.tenure {
  font-size: 16px;
  font-weight: 400;
  color: rgba(137, 137, 137, 1);
}

.divider {
  margin: 32px 0;
}

.eventName {
  margin-top: 0px;
  font-size: 16px;
  font-weight: 600;
  color: black;
}

.function {
  margin-top: 0px;
  font-size: 16px;
  font-weight: 400;
}

.site {
  margin-top: 0px;
  font-size: 16px;
  font-weight: 400;
  color: rgba(76, 73, 72, 1);
  text-transform: uppercase;
}

.quote {
  background-color: rgba(81, 177, 191, 0.2);
  font-size: 20px;
  font-weight: 400;
  padding: 15px;
  margin-top: 25px;
}

.rectangleImg {
  position: relative;
  top: 26px;
  left: 20px;
}

.storyPath {
  margin-top: 25px;
  background-color: #ffffff;
  padding: 25px;
}

.headerText {
  font-size: 24px;
  font-weight: 600;
}

.sectionAlignment {
  display: flex;
  justify-content: space-between;
}

.arrows {
  display: flex;
  width: 95px;
  justify-content: space-between;
}

.arrowBtn {
  border: 1px solid rgba(192, 192, 192, 1);
  height: 40px;
  width: 40px;
  padding: 10px;
  background: rgba(255, 255, 255, 1);
  color: rgba(192, 192, 192, 1);
}

.rightActive {
  border: 1px solid rgba(225, 36, 42, 1);
  height: 40px;
  width: 40px;
  padding: 10px;
  cursor: pointer;
  color: rgba(225, 36, 42, 1);
}

.rightActive:hover {
  border: 1px solid rgba(225, 36, 42, 1);
  background: rgba(225, 36, 42, 1);
  color: #ffffff;
}

.rightActive1 {
  border: 1px solid rgba(225, 36, 42, 1);
  height: 40px;
  width: 40px;
  padding: 10px;
  cursor: pointer;
  color: rgba(225, 36, 42, 1);
  background-color: #ffffff;
}

.video {
  width: 100%;
  height: 90%;
}

.mediaImage {
  width: 70%;
  height: 90%;
  object-fit: cover;
  margin-left: 15%;
}

.arrowPosition {
  position: absolute;
  right: 82px;
  margin-top: 6px;
}

.bottomPart {
  display: flex;
  justify-content: center;
}

.bottomCircles {
  display: flex;
  padding-top: 18px;
}

.circle {
  width: 10px;
  height: 10px;
  margin: 4px;
  border: 2px solid #ffffff;
  border-radius: 10px;
}

.activeCircle {
  width: 10px;
  height: 10px;
  margin: 4px;
  border: 2px solid #e1242a;
  border-radius: 10px;
  background: #e1242a;
}

@media (max-width: 710px) {
  .function {
    font-size: 12px;
  }

  .site {
    font-size: 8px;
  }
}

@media (max-width: 675px) {
  .bottomPart {
    justify-content: space-between;
  }
}

@media (max-width: 540px) {
  .mainContainer {
    padding-right: 20px;
  }

  .container {
    flex-wrap: wrap;
  }

  .sectionAlignment {
    flex-wrap: wrap;
  }

  .function {
    width: 20%;
    font-size: 10px;
  }

  .site {
    width: 20%;
    font-size: 8px;
  }

  .onBorderText {
    width: 20%;
    font-size: 8px;
  }

  .bottomPart {
    justify-content: space-between;
  }

  .storyContent2 {
    padding-bottom: 35px;
  }
}

blockquote {
  background-color: rgba(81, 177, 191, 0.2);
  font-size: 20px;
  font-weight: 400;
  padding: 15px;
  margin-top: 25px;
  color: rgba(13, 43, 48, 1);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

blockquote:before {
  content: "\"";
}

blockquote:after {
  content: "\"";
}

blockquote:before,
blockquote:after {
  font-size: 20px;
}