.container {
  /* background-color: #ffffff;
    padding: 30px 75px;
    position: relative; */
  background-color: white;
  padding-left: 4.5rem;
  padding-right: 5.5rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.headerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 27px;
}
.header {
  font-size: 36px;
  font-weight: 400;
}
.switchSite {
  display: flex;
  flex-direction: row;
}
.switchSiteLabel {
  font-size: 14px;
  font-weight: 400;
  color: rgba(76, 73, 72, 1);
}
.header {
  font-size: 36px;
  font-weight: 400;
  line-height: 48px;
  letter-spacing: 0px;
  text-align: left;
  color: #212b36;
}
.switchSite {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}
.switchSiteLabel {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #4c4948;
}
.switchSiteDropdown {
  width: 10rem;
  border: 1px solid #c0c0c0;
}
.dropdownWrap {
  position: relative;
  display: grid;
  grid-template-areas: "select";
  cursor: pointer;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 600;
}
.bodyContainer {
  background-color: white;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
