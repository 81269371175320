.container {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
.sideOptions {
  display: flex;
  flex-direction: column;
  width: 25%;
}
.stackRoleIcon {
  display: flex;
  gap: 10px;
}
.chartArea {
  display: block;
  width: 75%;
  height: auto;
}
.functionHeader {
  font-weight: 700;
  padding: 10px;
  border-bottom: 1px solid #c0c0c0;
  cursor: pointer;
}
.functionHeader:hover {
  color: black !important;
  background-color: #e6e6e6 !important;
  transition: 0.3s !important;
}
.functionListVal {
  display: flex;
  flex-direction: row;
  gap: 10px;
  border-bottom: 1px solid #c0c0c0;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  min-height: 36px;
  cursor: pointer;
}
.functionListVal:hover {
  color: black !important;
  background-color: #e6e6e6 !important;
  transition: 0.3s !important;
}
.optionsSection {
  border-top: 1px solid #c0c0c0;
  border-left: 1px solid #c0c0c0;
  border-right: 1px solid #c0c0c0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
}
.noteLabel {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #212b36;
}

.selectRole {
  padding-right: 5px;
}
