.imageContainer {
	position: relative;
	text-align: center;
}

.textPosition {
	position: absolute;
	top: 30%;
	left: 9%;
	text-align: left;
	background-color: #ffffff;
	padding: 30px;
	border: 1px solid rgba(0, 0, 0, 1);
}

.header {
	font-size: 36px;
	font-weight: 400;
}

.subHeader {
	font-size: 16px;
	font-weight: 400;
}

.subPartText {
	text-transform: capitalize;
}

@media (max-width: 1200px) {
	.textPosition {
		padding: 15px;
	}
}

@media (max-width: 1040px) {
	.textPosition {
		padding: 5px;
	}

	.header {
		font-size: 32px;
	}

	.subHeader {
		font-size: 12px;
	}
}

@media (max-width: 790px) {
	.textPosition {
		padding: 15px;
	}

	.header {
		font-size: 26px;
	}

	.subHeader {
		font-size: 10px;
	}
}

@media (max-width: 540px) {
	.textPosition {
		padding: 5px;
	}

	.header {
		font-size: 16px;
	}

	.subHeader {
		font-size: 8px;
	}
}