.mainContainer {
  position: relative;
  background-color: #ffffff;
  padding: 50px 40px;
  justify-content: center;
}
@media (max-width: 800px) {
  .mainContainer {
    padding: 20px 15px;
  }
}
.col {
  border: 1px solid rgba(231, 231, 231, 1);
  padding: 20px;
}
.bottomSpace {
  margin-bottom: 18px;
}
.question {
  font-size: 16px;
  font-weight: 600;
}
.answer {
  margin-top: 8px;
  font-size: 16px;
  font-weight: 400;
  color: rgba(76, 73, 72, 1);
}