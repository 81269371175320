.mainContent {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  padding: 0 75px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.header {
  font-size: 36px;
  font-weight: 400;
}
.subHeaderSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  margin: 40px 0px 40px 0px;
}
.subHeader {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  width: 80%;
  padding: 10px 10px;
  /* padding: 0px 40px; */
}
.subContent {
  display: flex;
}
@media (max-width: 1100px) {
  .subContent {
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
@media (max-width: 800px) {
  .subContent {
    flex-direction: column;
    align-content: center;
  }
}
.subParts {
  background-color: #ffffff;
  border: 1px solid #d1d0d0;
  margin: 10px 15px 10px 0px;
  text-align: center;
  width: 298px;
  height: 198px;
}
.imageBlock {
  margin: 15px 0;
  margin-top: 21px;
}
.imageContainer {
  width: 58px;
  height: 58px;
  border: 1px solid;
  border-radius: 50%;
  text-align: center;
  display: block;
  margin: auto;
  object-fit: cover;
  padding: 11px;
}
.color1 {
  padding: 7px;
  background-color: rgba(155, 114, 176, 0.2);
}
.color2 {
  background-color: rgba(81, 177, 191, 0.2);
}
.color3 {
  background-color: rgba(218, 82, 131, 0.2);
}
.color4 {
  background-color: rgba(235, 168, 0, 0.2);
}
.image4 {
  height: -webkit-fill-available;
  margin: auto;
}
.heading {
  font-size: 20px;
  font-weight: 600;
}
.subHeading {
  font-size: 14px;
  font-weight: 400;
  padding: 0 12px;
  margin-bottom: 25px;
}
