.jvectormap-tip {
  position: absolute !important;
  border: solid 1px #cdcdcd !important;
  border-radius: 3px !important;
  background: white !important;
  color: black !important;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif !important;
  padding: 3px !important;
  z-index: 10 !important;
}
.jvectormap-element {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif !important;
  font-size: 10px !important;
  font-weight: 600 !important;
  line-height: 12px !important;
  letter-spacing: 0em !important;
  text-align: center !important;
}
.hoverTooltip {
  width: 250px;
  height: 220px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 99;
}
.hoverContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.hoverFlag {
  width: 16px;
  height: 12px;
  padding-right: 2px;
}
.hoverSiteImage {
  width: 220px;
  height: 150px;
}
.hoverlabel {
  margin-top: 5px;
  margin-left: 5px;
  padding: 2px;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 4px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
}
._u5cITtZnGk9D_6uoElx .jvectormap-zoomin {
  position: absolute !important;
  top: 25rem !important;
  left: 95% !important;
  background: red !important;
  color: white !important;
  cursor: pointer !important;
  box-sizing: content-box !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  width: 25px !important;
  height: 25px !important;
}

._u5cITtZnGk9D_6uoElx .jvectormap-zoomout {
  position: absolute !important;
  top: 27rem !important;
  left: 95% !important;
  background: #e7e7e7 !important;
  color: #4c4948 !important;
  cursor: pointer !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  box-sizing: content-box !important;
  width: 25px !important;
  height: 25px !important;
}
