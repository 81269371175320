.mainBlock {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 85px;
}
.roleDropdown {
  display: flex;
  flex-direction: row;
  /* gap: 6rem; */
  gap: 1rem;
  margin-bottom: 1rem;
}
@media (max-width: 730px) {
  .roleDropdown {
    flex-direction: column;
  }
}
.dropdownContainer {
  display: flex;
  flex-direction: column;
  column-gap: 1rem;
  /* width: 20%; */
  width: 250px;
}
.descriptionText {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.header {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 48px;
  letter-spacing: 0px;
  text-align: left;
}

.imageText {
  width: 566px;
  height: 170px;
}
.menuButton {
  padding: 5px;
  /* width: 23%; */
  width: 100%;
  display: flex;
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin: 4px 5px;
}
.menuButtonDisabled {
  padding: 5px;
  /* width: 23%; */
  width: 100%;
  display: flex;
  font-size: 14px;
  color: rgba(192, 192, 192, 1);
  cursor: default;
  margin: 4px 5px;
}
.menuHover:hover {
  background-color: #000000 !important;
  color: #ffffff;
}
.subSelection li:hover {
  background-color: #000000 !important;
  color: #ffffff;
}
.subSelection li div:hover {
  background-color: #000000 !important;
  color: #ffffff;
}
:global(.szh-menu__item--hover) {
  background-color: #000000 !important;
  color: #ffffff;
}
.label {
  font-size: 14px;
  font-weight: 600;
}
.inactiveLabel {
  font-size: 14px;
  font-weight: 600;
  color: rgba(192, 192, 192, 1);
}
.customSelect {
  display: flex;
  width: 100%;
  justify-content: space-between;
  border: 1px solid;
}
.disabledCustomSelect {
  display: flex;
  width: 100%;
  justify-content: space-between;
  border: 1px solid rgba(192, 192, 192, 1);
}
.arrowContainer {
  border-left: 1px solid;
  padding: 3px;
  cursor: pointer;
}
.arrowUpContainer {
  background-color: #000000;
  color: #ffffff;
}
.disabledArrowContainer {
  border-left: 1px solid rgba(192, 192, 192, 1);
  padding: 3px;
}
.arrowIcon {
  font-size: 30px;
  margin-bottom: 4%;
}
.disabledArrowIcon {
  font-size: 30px;
  margin-bottom: 4%;
  color: rgba(192, 192, 192, 1);
}
.selectText {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 600;
}