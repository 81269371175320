.mainContainer {
  margin: 30px 75px;
}
.header {
  font-size: 36px;
  font-weight: 400;
}
.button {
  margin-top: 35px;
  padding: 8px 20px;
  border: 1px solid #d1d0d0;
  font-weight: 600;
  color: rgba(76, 73, 72, 1);
}
.activeButton {
  margin-top: 35px;
  padding: 8px 20px;
  background-color: rgba(225, 36, 42, 1);
  color: #ffffff;
  border: 1px solid rgba(225, 36, 42, 1);
  font-weight: 600;
  cursor: default;
}
.button:hover {
  background-color: #ffffff;
  color: #000000;
  border: 1px solid #000000;
}
@media (max-width: 540px) {
  .mainContainer {
    margin: 30px;
  }
}
@media (max-width: 300px) {
  .mainContainer {
    margin: 10px;
  }
}