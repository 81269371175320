.mainContainer {
  position: relative;
  background-color: #ffffff;
  padding: 50px 24px;
  justify-content: center;
}
.row {
  display: flex;
  flex-wrap: wrap;
  column-gap: 7px;
  margin-left: 10px;
}
@media (max-width: 1040px) {
  .row {
    justify-content: space-between;
  }
}
@media (max-width: 800px) {
  .row {
    justify-content: center;
  }
}
@media (max-width: 540px) {
  .row {
    justify-content: center;
  }
  .col {
    padding: 10px !important;
  }
}
.col {
  /* padding: 10px 2px 10px 28px;
  width: 275px; */
  width: 280px;
  /* padding: 8px 15px; */
  padding: 1%;
}
.image {
  width: 250px;
  height: 250px;
}
.col img.image:hover {
  transform: scale(1.10);
  cursor: default;
}
.name {
  margin: 15px 15px 0px 5px;
  font-size: 16px;
  font-weight: 600;
  color: rgba(0, 0, 0, 1);
}
.description {
  width: 245px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(76, 73, 72, 1);
  margin: 5px;
  text-align: left;
}
.hoveredName {
  margin: 15px 15px 0px 5px;
  font-size: 16px;
  font-weight: 600;
  color: rgba(225, 36, 42, 1);
  cursor: default;
}
.hoveredDescription {
  width: 245px;
  font-size: 14px;
  font-weight: 400;
  margin: 5px;
  text-align: left;
  text-decoration: underline;
  cursor: default;
}