.siteNote {
  display: flex;
  gap: 1rem;
  padding: 8px 16px 8px 16px;
  background-color: #9b72b033;
  border: 1px solid black;
  margin: 1.5rem 0 1rem 0;
}
.note {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  color: #4c4948;
}
.headerText {
  font-weight: 700;
}

/* Graph container styles */
.graphContainer {
  border: 1px solid #efefef;
}
.graphContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.spacing {
  margin: 0 40px;
}
.arrowBtn {
  border: 1px solid rgba(192, 192, 192, 1);
  height: 40px;
  width: 40px;
  padding: 10px;
  background: rgba(255, 255, 255, 1);
  color: rgba(192, 192, 192, 1);
}

.rightActive {
  border: 1px solid rgba(225, 36, 42, 1);
  height: 40px;
  width: 40px;
  padding: 10px;
  cursor: pointer;
  background: rgba(225, 36, 42, 1);
  color: #ffffff;
}

.rightActive:hover {
  border: 1px solid #000000;
  background: #000000;
  color: #ffffff;
}

.leftBtn {
  transform: translateX(-21px);
  overflow: visible;
  width: 3.5%;
  height: 3.5%;
  padding: 0.5%;
}

.rightBtn {
  transform: translateX(21px);
  overflow: visible;
  width: 3.5%;
  height: 3.5%;
  padding: 0.5%;
}

/* svg styles */
.chartContainer {
  width: 95%;
  height: 90%;
  overflow: auto;
  margin: auto;
  display: block;
}

.chart {
  /* border: 1px solid #efefef; */
  /* padding-top: 30px; */
  display: flex;
  justify-content: center;
}

.departmentText {
  text-align: center;
  font-size: 12px;
  font-weight: 400;
}

/* bottom horizontal lines */
.bottomLines {
  display: flex;
  justify-content: center;
  margin-bottom: 22px;
}
.hrLine {
  width: 30px;
  margin: 4px;
  border: 2px solid rgba(192, 192, 192, 1);
}
.hrLineActive {
  width: 30px;
  margin: 4px;
  border: 2px solid #e1242a;
}

.selectRole {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 11px;
  font-weight: 600;
  color: black;
  width: 100%;
  height: 70px;
}
.selectRole:hover {
  fill: red;
  text-decoration: underline;
  cursor: pointer;
}
.departmentBox {
  /* width: 200px; */
  height: 11%;
  /* border: 1px solid black; */
}

/* Note Content */
.noteContent {
  display: flex;
  margin-top: 45px;
  margin-bottom: 10px;
}
.rightArrow {
  font-size: 20px;
  color: rgba(225, 36, 42, 1);
  margin-right: 12px;
}
.note {
  font-size: 14px;
  font-weight: 400;
}
