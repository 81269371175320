@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  box-sizing: border-box;
  background-color: #efefef;
  scrollbar-gutter: stable both-edges;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
*,
*::after,
*::before {
  box-sizing: border-box;
}
:root {
  --spacing-xlarge: 4rem; /* 64px */
  --spacing-large: 2rem; /* 32px */
  --spacing-medium: 1.5rem; /* 24px */
  --spacing-base: 1rem; /* 16px */
  --spacing-small: 0.5rem; /* 8px */
  --spacing-xsmall: 0.25rem; /* 4px */

  --c-red: #e1242a;
  --c-red-50: #fac5c1;
  --c-red-dark: #bd120a;

  --c-black: #000000;
  --c-grey-1: #000000;
  --c-grey-2: #4c4948;
  --c-grey-3: #898989;
  --c-grey-4: #c0c0c0;
  --c-grey-5: #dddddd;
  --c-grey-6: #efefef;
  --c-white: #ffffff;

  --c-orange: #ea5532;

  --c-pink: #da5283;
  --c-pink-80: #e1759c;
  --c-purple: #9b72b0;
  --c-purple-40: #d7c7df;
  --c-purple-60: #c3aad0;
  --c-blue: #4c9bcf;
  --c-teal: #51b1bf;
  --c-green: #abb436;
  --c-yellow: #eba800;

  /* Tints */
  --c-yellow-80: #efb933;
  --c-yellow-60: #f3cb66;
  --c-yellow-40: #f7dc99;
  --c-yellow-20: #fbeecc;
  /* Font sizes */
  --fs-xlarge: 36px;
  --fs-large: 21px;
  --fs-medium: 18px;
  --fs-base: 16px;
  --fs-small: 14px;
  --fs-xsmall: 12px;
}
