.mainContainer {
  background-color: #efefef;
  padding: 30px 75px;
}

.successStories {
  display: flex;
  justify-content: space-between;
  margin: 0 10px;
}

.successStories1 {
  display: flex;
  justify-content: space-between;
  margin: 0 10px;
  margin-right: 14px;
}

.header {
  font-size: 36px;
  font-weight: 400;
}

.subText {
  font-size: 16px;
  font-weight: 400;
  color: rgba(225, 36, 42, 1);
  align-self: center;
  cursor: pointer;
}

/* all stories */
.container {
  display: flex;
  justify-content: space-between;
}

.container1 {
  display: flex;
  /* justify-content: space-between; */
  flex-wrap: wrap;
}

.storyContent {
  margin: 10px;
  background-color: #ffffff;
  padding: 20px;
  flex-basis: 100%;
}

.storyContent1 {
  margin: 10px;
  background-color: #ffffff;
  padding: 20px;
  width: 31.6%;
}

.storyContent:hover {
  box-shadow: -2px 10px 10px 10px #c5bfbf;
}

.storyContent1:hover {
  box-shadow: -2px 10px 10px 10px #c5bfbf;
}

.profile {
  display: flex;
}

.profilePicture {
  width: 55px;
  height: 70px;
  object-fit: cover;
}

.profileDetails {
  margin-left: 12px;
  margin-top: 5px;
}

.divider {
  margin: 18px 0;
}

.eventName {
  margin-top: 0px;
  font-size: 14px;
  font-weight: 600;
  color: black;
}

.function {
  margin-top: 0px;
  font-size: 14px;
  font-weight: 400;
  color: #4c4948;
}

.site {
  margin-top: 0px;
  font-size: 14px;
  font-weight: 400;
  color: #898989;
  text-transform: uppercase;
}

.quote {
  background-color: rgba(81, 177, 191, 0.2);
  font-size: 14px;
  font-weight: 600;
  padding: 15px;
  margin-top: 25px;
}

.rectangleImg {
  position: relative;
  top: 26px;
  left: 20px;
}

:global(.custom-timeline .rs-timeline-item .rs-timeline-item-tail) {
  top: 24px !important;
  left: 6px !important;
  height: 76% !important;
}

:global(.custom-timeline .rs-timeline-item-last .rs-timeline-item-tail) {
  height: 0px !important;
}
blockquote:before {
  content: open-quote;
}

blockquote:after {
  content: close-quote;
}

blockquote:before,
blockquote:after {
  font-size: 20px;
}

@media (max-width: 1320px) {
  .storyContent1 {
    width: 31.4%;
  }
}

@media (max-width: 1150px) {
  .storyContent {
    width: 375px;
  }

  .storyContent1 {
    width: 31%;
  }
}

@media (max-width: 1040px) {
  .container {
    flex-wrap: wrap;
  }

  .storyContent1 {
    width: 45%;
  }
}

@media (max-width: 950px) {
  .container1 {
    flex-wrap: wrap;
    justify-content: center;
  }
}

@media (max-width: 765px) {
  .storyContent1 {
    width: 100%;
  }
}

@media (max-width: 540px) {
  .mainContainer {
    padding-right: 20px;
  }

  .container {
    flex-wrap: wrap;
  }
}