@tailwind base;
@tailwind components;
@tailwind utilities;
.header {
  /* @apply sm:w-auto md:w-auto xl:w-auto; */
  position: absolute;
  margin: 0;
  width: 100%;
}
.navigation {
  @apply flex-col md:flex-row justify-center md:justify-between;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-left: 3rem;
  padding-right: 3rem;
  background: #ffffff;
  border-bottom: 1px solid red;
  align-items: center;
}
.logoHolder {
  @apply m-auto md:m-0;
  position: relative;
  width: fit-content;
}
.logo {
  display: flex;
  width: max-content;
  padding-top: 1rem;
}
.tabs {
  @apply flex-col md:flex-row gap-1 md:gap-[1];
  display: flex;
  flex-wrap: wrap;
  gap: 3rem;
  position: relative;
}
.button {
  @apply p-[1px] md:p-[1px];
  background-color: white;
  color: #4c4948;
  padding: 38px 16px 27px 16px;
  font-weight: 600;
}
.buttonActive {
  background-color: white;
  color: red;
  border-bottom: 2px solid red;
  padding: 38px 16px 27px 16px;
  font-weight: 600;
}
.button:hover {
  background-color: black;
  color: white;
  border: none;
}
.languageContainer {
  color: "white";
  display: flex;
  align-items: center;
}
.footer {
  /* position: sticky; */
  bottom: 0;
  background: #ffffff;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  color: rgba(137, 137, 137, 1);
  padding-left: 4.5rem;
  padding-right: 4.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-top: 2px solid rgba(221, 221, 221, 1);
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}
