.mainBlock {
  border: 1px solid rgba(221, 221, 221, 1);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.image {
  width: 385px;
  height: 385px;
}
.descriptionText {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.description {
  font-size: 36px;
  font-style: 600;
}
@media (max-width: 1040px) {
  .descriptionText {
    margin-top: 10px;
  }
}
@media (max-width: 800px) {
  .descriptionText {
    margin: 15px 15px;
  }
}