.loaderOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  overflow: hidden;
}
.error {
  width: 30rem;
  height: 7rem;
  position: relative;
  background-color: #ffffff;
  color: black;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 600;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 10px;
}
.closeBtn {
  margin-block-start: auto;
}
