.hovered {
  text-decoration: underline;
  color: red;
  cursor: pointer;
}
.node-text {
  font-size: 12px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  word-break: break-word;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-overflow: ellipsis;
  padding: 4px;
}
.node-text:hover {
  color: red;
}
.node-rect {
  display: flex;
  align-items: center;
  /* width: 110px; */
  /* height: 50px; */
}
.solid {
  stroke: solid;
}

.dashed {
  stroke-dasharray: 5, 5;
}
