@tailwind base;
@tailwind components;
@tailwind utilities;
.siteSection {
  position: relative;
  background-color: white;
  padding-left: 4.5rem;
  padding-right: 5.5rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.siteheader {
  font-size: 36px;
  font-weight: 400;
  line-height: 48px;
  letter-spacing: 0px;
  text-align: left;
  color: #212b36;
}
.siteNote {
  display: flex;
  gap: 1rem;
  padding: 8px 16px 8px 16px;
  background-color: #9b72b033;
  border: 1px solid black;
  margin: 1rem 0 1rem 0;
}
.note {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  color: #4c4948;
}
.selectSite {
  @apply flex-col md:flex-row gap-1 md:gap-[1];

  display: flex;
  gap: 2rem;
  align-items: flex-end;
}
.siteDropdown {
  display: flex;
  flex-direction: column;
}
/* .dropdown {
  width: 17rem;
} */
.label {
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
.maplegend {
  display: flex;
  margin-left: 5rem;
}
.circle {
  margin-right: 0.5rem;
}
.maplegendlabel {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}
.mapArea {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  margin-top: 2rem;
}
.siteDetails {
  width: 18rem;
  height: 28rem;
  /* padding: 5px 6px 5px 6px; */
  display: flex;
  flex-direction: column;
  row-gap: 29px;
  flex-wrap: wrap;
  /* align-content: center; */
  margin-top: 2rem;
}
.siteInfoLabel {
  display: flex;
  gap: 1rem;
  align-items: center;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #4c4948;
}
.siteImage {
  width: 220px;
  height: 150px;
}
.siteInfoIcon {
  display: flex;
  width: 20px;
  height: 20px;
  justify-content: space-evenly;
}
.siteInfoButton {
  background-color: #e1242a;
  color: white;
  width: 256px;
  height: 56px;
  padding: 8px 20px 8px 20px;
  gap: 10px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
}
.mapData {
  flex: 1;
  width: 70%;
  transition: width 0.3s ease;
  /* border: 1px solid black; */
}

.wrap {
  display: flex;
  grid-gap: var(--spacing-small);
  align-items: center;
  font-size: var(--fs-base);
  font-weight: 500;
  font-family: var(--font-plex);
  z-index: 9;
}
.sitewrap {
  position: relative;
  display: grid;
  grid-template-columns: max-content max-content;
  align-items: center;
  font-family: var(--font-plex);
  font-weight: 500;
  grid-gap: var(--spacing-small);
}

.dropdownWrap {
  position: relative;
  border: 1px solid var(--c-grey-4);
  display: grid;
  grid-template-areas: "select";
  cursor: pointer;
  width: 16rem;
  font-size: 14px;
}

.selectdropdown {
  appearance: none;
  background-color: transparent;
  border: none;
  padding: 0 1em 0 0;
  margin: 0;
  width: 100%;
  margin-right: 39px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  border-radius: 0;
  outline: none;
  grid-area: select;
  padding: var(--spacing-small);
}

.selecticon {
  grid-area: select;
  justify-self: end;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 39px;
  height: 39px;
  background-color: #fff;
  color: #000;
  pointer-events: none;
  border-left: 1px solid var(--c-grey-4);
  transition: 0.2s ease-out;
}

.selectdropdown:focus {
  border-color: var(--c-black);
}

.dropdownWrap:hover .selecticon {
  background: black;
  color: #fff;
}

.select {
  width: 250px;
  --rmsc-radius: 0 !important;
  --rmsc-gray: var(--c-black) !important;
  --rmsc-border: var(--c-grey-4) !important;
  --rmsc-h: 37px !important;
  --rmsc-selected: #fff !important;
  --rmsc-primary: #000 !important;
  --rmsc-main: #000 !important;
  --rmsc-p: var(--spacing-small) !important;
  font-size: var(--fs-small);
  font-family: var(--font-plex);
}

:global(.rmsc) * {
  cursor: pointer;
}

:global(.rmsc) :global(.options) {
  border: 1px solid var(--c-grey-4);
  border-bottom: none;
}

:global(.rmsc) :global(.dropdown-heading-dropdown-arrow) {
  border-left: 1px solid var(--c-grey-4);
  height: 39px;
  margin-top: -8px;
  margin-bottom: -8px;
  margin-right: -8px;
  margin-left: -8px;
  width: 39px;
  display: flex;
  align-items: center;
  justify-content: center;
}

:global(.rmsc) :global(.dropdown-container),
:global(.rmsc) :global(.dropdown-content) * {
  box-shadow: none !important;
}

:global(.rmsc) :global(.select-item) {
  border-bottom: 1px solid var(--c-grey-4);
  padding: var(--spacing-small);
  align-items: center;
}

:global(.rmsc) :global(input[type="checkbox"]) {
  position: relative;
  top: 1px;
  accent-color: var(--c-red);
}

:global(.rmsc:focus-within .dropdown-heading-dropdown-arrow) {
  background-color: var(--c-black);
  stroke: var(--c-white);
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid var(--c-grey-4);
  height: 100%;
  width: 39px;
  transform: translateX(8px);
  transition: 0.3s ease-out;
}

:global(.rmsc:focus-within) .icon {
  border-left: 1px solid var(--c-black);
  background-color: var(--c-black);
  stroke: var(--c-black);
}

:global(.rmsc:focus-within) .icon svg {
  transform: rotate(180deg);
}

:global(.rmsc:focus-within) .icon svg path {
  stroke: var(--c-white);
}
