.container {
  margin: auto;
  width: 100%;
  height: 500px;
  /* z-index: 99; */
}

.customMapClass .jvectormap-marker {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: red;
  box-sizing: border-box;
  transform: translate(-50%, -50%);
}
.jvectormap-tip {
  background-color: white;
}
.customMapClass .jvectormap-zoomin {
  position: absolute;
  left: 10px;
  border-radius: 3px;
  background: red;
  padding: 3px;
  color: white;
  cursor: pointer;
  line-height: 10px;
  text-align: center;
  box-sizing: content-box;
  width: 10px;
  height: 10px;
  top: 10px;
}
