.mainBlock {
  border: 1px solid rgba(221, 221, 221, 1);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding: 30px;
}
.image {
  width: 20%;
  height: 350px;
}
.descriptionHeader {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 48px;
  text-align: left;
  margin-bottom: 20px;
}
.circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border: 1px solid black;
  border-radius: 50%;
  background-color: #efefef;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 26px;
  text-align: left;
  margin-right: 10px;
}
.descriptionText {
  display: flex;
  flex-direction: row;
}
.subHeader {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #212b36;
  margin-bottom: 5px;
}
.divText {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #637381;
  margin-bottom: 15px;
}
.description {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media (max-width: 1040px) {
  .descriptionText {
    margin-top: 10px;
  }
}
@media (max-width: 800px) {
  .descriptionText {
    margin: 15px 15px;
  }
}
