.container {
  width: 100%;
  height: 100%;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.backButton {
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  cursor: pointer;
}
.roleHeader {
  font-size: 36px;
  font-weight: 400;
  display: table;
  align-items: center;
}
.infoCircle {
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid black;
  cursor: pointer;
}
.hoverDiv {
  position: absolute;
  width: 328px;
  height: 152px;
  display: flex;
  flex-direction: column;
  padding: 30px;
  justify-content: center;
  background-color: white;
  z-index: 999;
  box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.3);
}

/* .hoverDiv::before {
  content: "";
  width: 20px;
  height: 20px;
  position: absolute;
  top: -10px;
  left: 280px;
  background-color: white;
  transform: rotate(45deg);
} */
.hoverDivHeader {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}
.hoverDivBody {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}
.roleTags {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 25px;
  margin-bottom: 25px;
}
.roleTag {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  width: fit-content;
  height: 28px;
  padding: 2px 11px 2px 11px;
  gap: 6px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
}
.roleBody {
  display: flex;
  flex-direction: row;
  gap: 3rem;
}
.sideInfoCard {
  width: 299px;
  min-height: 402px;
  display: flex;
  flex-direction: column;
  background-color: #9b72b0;
  /* box-shadow: 0px 40px 80px 0px #47118e3d; */
  box-shadow: 0px 1px 2px 0px #260d450a;
  padding: 20px;
}
.sideInfoCardField {
  display: flex;
  flex-direction: row;
  padding: 5px;
  align-items: center;
  gap: 7px;
}
.sideInfoCardIcon {
  width: 24px;
  height: 24px;
}
.sideInfoCardLabel {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  color: white;
  font-weight: 400;
}
.titleblock {
  height: 48px;
  padding: 12px 20px 12px 20px;
  background-color: #efefef;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  border-bottom: 1px solid #c0c0c0;
}
.jobRespBlock {
  /* width: max-content; */
  height: auto;
  border: 1px solid #c0c0c0;
  margin-bottom: 10px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}
.techSkillBlock {
  /* width: max-content; */
  height: auto;
  border: 1px solid #c0c0c0;
  margin-bottom: 10px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}
.digitalSkillBlock {
  /* width: max-content; */
  height: auto;
  border: 1px solid #c0c0c0;
  margin-bottom: 10px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}
.profSkillBlock {
  /* width: max-content; */
  height: auto;
  border: 1px solid #c0c0c0;
  margin-bottom: 10px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}
.contentBlock {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding: 20px;
}
.sentence {
  display: flex;
  flex-direction: row;
  gap: 5px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: 400;
  /* line-height: 14px; */
  padding: 2px;
  margin-bottom: 10px;
}
.seeMoreBtn {
  display: flex;
  justify-content: flex-end;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: right;
  text-decoration: underline;
  cursor: pointer;
}
.splitter {
  width: 260px;
  position: relative;
  margin-top: 10px;
  margin-bottom: 10px;
  border: 1px solid rgba(221, 221, 221, 1);
  /* left: 50px; */
}
