.popupOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(250, 250, 250, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  overflow: hidden;
}
.container {
  width: 967px;
  height: 591.18px;
  /* width: 30rem;
  height: 7rem; */
  position: relative;
  /* background-color: #ffffff; */
  color: black;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.mainBlock {
  width: 90%;
  height: 90%;
  background-color: white;
  clip-path: polygon(
    0 0,
    66% 0,
    12% 0%,
    20% 10%,
    100% 10%,
    100% 100%,
    7% 100%,
    0 93%
  );

  /* polygon(0 0, 60% 0, 100% 0, 100% 99%, 100% 100%, 7% 100%, 0 93%); */
}
.cards {
  height: 380px;
  overflow-y: auto;
}
.cards::-webkit-scrollbar {
  width: 5px;
  color: #c0c0c0;
}
.cards::-webkit-scrollbar {
  width: 5px;
  height: 0px;
}
.cards::-webkit-scrollbar-track {
  background: #c0c0c0;
}
.cards::-webkit-scrollbar-thumb {
  background: #888;
}
.cards::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.cards::after {
  content: "";
  clear: both;
  display: table;
}
.tabLook {
  width: 20%;
  position: relative;
  left: 0;
  top: 0.5px;
  opacity: 1;
  height: 10%;
  /* border-left: 1px solid #9b72b0; */
  /* border-top: 1px solid #9b72b0; */
  /* border-right: 1px solid #9b72b0; */
  box-shadow: -11px -11px 12px 0px #5d446a33;
  clip-path: polygon(0 0, 60% 0, 60% 0%, 100% 100%, 100% 100%, 0 100%, 0 100%);
}

.body {
  /* width:90%; */
  height: 90%;
  /* border: 1px solid #9b72b0; */
  opacity: 1;
  padding: 10px;
  box-shadow: 0px 16px 40px 0px #5d446a33;
  clip-path: polygon(0 0, 60% 0, 100% 0, 100% 99%, 100% 100%, 7% 100%, 0 93%);
}
.stack {
  margin-bottom: 20px;
  margin-left: 5px;
  margin-right: 5px;
  width: 33%;
  float: left;
  zoom: 90%;
}
.stackhead {
  display: flex;
  flex-direction: row;
  background-color: white;
  justify-content: space-between;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  text-align: left;
  width: 14rem;
  padding: 10px;
  /* border-radius: 0 0 20px 0; */
  clip-path: polygon(100% 0%, 100% 50%, 92% 100%, 0 100%, 0% 0);
}
.stackRolecount {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid #898989;
  display: flex;
  align-items: center;
  justify-content: center;
}
.stackbody {
  background-color: white;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  width: 17rem;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  padding: 10px;
  position: relative;
  top: -10px;
  clip-path: polygon(100% 0%, 100% 56%, 90% 100%, 0 100%, 0% 0);
}
.stackbodyHead {
  content: "";
  width: 60px;
  height: 25px;
  background-color: white;
  position: relative;
  left: 212px;
  top: -6px;
  clip-path: polygon(100% 0, 100% 0, 100% 100%, 0 100%, 36% 0);
}
.splitter {
  width: 100%;
  position: relative;
  margin-top: 10px;
  margin-bottom: 15px;
}
.smallViewBtn {
  display: flex;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: right;
  position: relative;
  right: 0;
  flex-direction: row;
  justify-content: flex-end;
  cursor: pointer;
}

.roletag {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  padding: 3px;
}

.selectRole {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: black;
}
.selectRole:hover {
  color: red;
  text-decoration: underline;
  cursor: pointer;
}
