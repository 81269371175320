/*  ---- StoryPath file styles ---- */


.timelineWrapper {
  position: relative;
  width: 100%;
  margin: auto;
  height: 300px;
}

.timelineWrapper .middleLine {
  position: absolute;
  width: 100%;
  height: 5px;
  top: 50%;
  transform: translateY(-50%);
  background: #d9d9d9;
}

.timelineWrapper .dottedMiddleLine {
  position: absolute;
  width: 100%;
  height: 5px;
  top: 50%;
  transform: translateY(-50%);
  border-top: dashed 3px;
  border-color: rgba(192, 192, 192, 1);
}

.arrowButton {
  position: absolute;
  width: 100%;
  height: 45px;
  top: 42.2%;
  left: 50%;
  color: #d9d9d9;
}

.box {
  width: 25%;
  position: relative;
  min-height: 300px;
  float: left;
}

.box .date {
  position: absolute;
  top: 50%;
  left: 0px;
  transform: translateY(-50%);
  width: 25px;
  height: 25px;
  border-radius: 100%;
  background: rgba(225, 36, 42, 1);
  border: 2px solid rgba(225, 36, 42, 1);
}

.box .borderDate {
  position: absolute;
  top: 50%;
  left: 0px;
  transform: translateY(-50%);
  width: 25px;
  height: 25px;
  border-radius: 100%;
  background: rgba(225, 36, 42, 1);
  border: 3.5px solid #eb8f8f;
}

.date p {
  text-align: center;
  margin-top: 3px;
  margin-bottom: 0px;
}

.box .boxContent {
  /* border-radius: 5px; */
  /* background-color: #00b0bd; */
  /* width: 180px; */
  width: 275px;
  position: absolute;
  /* left: -65px; */
  left: 5px;
  /* padding: 15px; */
}

.boxContent p {
  margin: 0;
  /* color: white; */
}

.boxBottom .boxContent {
  top: 56%;
}

.boxTop .boxContent {
  bottom: 62%;
}

.boxContent::before {
  content: " ";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  border: 10px solid transparent;
}

.onBorderText {
  position: absolute;
  top: 38%;
  left: 5px;
  font-size: 12px;
  font-weight: 400;
  color: rgba(33, 43, 54, 1);
  font-style: italic;
}

.image {
  position: absolute;
  top: 1px;
  left: 1px;
}

.flagImage {
  position: absolute;
  top: 1px;
  left: 3px;
  width: 15px;
  height: 20px;
}

.eventName {
  font-size: 16px;
  font-weight: 600;
}

.function {
  font-size: 16px;
  font-weight: 400;
}

.site {
  font-size: 12px;
  font-weight: 600;
  color: rgba(76, 73, 72, 1);
}

.noQuote {
  margin: 75px 0;
}

@media (max-width: 1195px) {
  .eventName {
    width: 80%;
    font-size: 14px;
  }

  .function {
    width: 80%;
    font-size: 14px;
  }
}

@media (max-width: 980px) {
  .eventName {
    width: 60%;
  }

  .function {
    width: 60%;
  }
}

@media (max-width: 750px) {
  .eventName {
    font-size: 12px;
    width: 50%;
  }

  .function {
    font-size: 12px;
    width: 50%;
  }

  .site {
    font-size: 8px;
  }
}

@media (max-width: 675px) {
  .bottomPart {
    justify-content: space-between;
  }
  .storyPath {
    padding: 40px;
  }
}

@media (max-width: 675px) {
  .mainContainer {
    padding-right: 20px;
  }

  .container {
    flex-wrap: wrap;
  }

  .sectionAlignment {
    flex-wrap: wrap;
  }

  .eventName {
    width: 20%;
    font-size: 10px;
  }

  .function {
    width: 20%;
    font-size: 10px;
  }

  .site {
    width: 20%;
    font-size: 8px;
  }

  .onBorderText {
    width: 20%;
    font-size: 8px;
  }

  .bottomPart {
    justify-content: space-between;
  }

  .storyContent2 {
    padding-bottom: 35px;
  }
}