.container {
  border: 1px solid rgba(221, 221, 221, 1);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 15px;
  overflow: auto;
}
.chart {
  width: 880px;
  min-height: 19rem;
  /* max-height: 39rem; */
  border: 1px solid white;
  overflow: auto;
  /* display: flex;
  justify-content: center; */
}

.chart::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  color: #dddddd;
}
.chart::-webkit-scrollbar-track {
  background: #c0c0c0;
}
.chart::-webkit-scrollbar-thumb {
  background: #888;
}
.chart::-webkit-scrollbar-thumb:hover {
  background: #555;
  cursor: pointer;
}
.header {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: 700;
}

.legend {
  display: flex;
  flex-direction: row;
  float: left;
  gap: 25px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 400;
}
.legend1 {
  display: flex;
  flex-direction: row;
  gap: 7px;
  align-items: center;
}
.dotted {
  height: 1px;
  width: 45px;
  border: 1px dashed black;
}

.block {
  width: 45px;
  height: 14px;
  background-color: #dddddd;
}
