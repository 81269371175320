.chartArea {
  display: flex;
  flex-direction: row;
  zoom: 90%;
  height: auto;
}

.siteNote {
  display: flex;
  gap: 1rem;
  padding: 8px 16px 8px 16px;
  background-color: #9b72b033;
  border: 1px solid black;
  margin: 1rem 0 1rem 0;
}
.note {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  color: #4c4948;
}
.dialView {
  display: flex;
  width: 67%;
  height: 50%;
  float: left;
  justify-content: flex-end;
  align-items: center;
  margin-top: 2.75rem;
}
.circle {
  margin-top: 5rem;
  margin-bottom: 5rem;
  width: 457px;
  height: 457px;
  border-radius: 50%;
}
.sideDiv {
  width: 225px;
  height: 82px;
}
.folderView {
  display: flex;
  flex-direction: column;
  width: 33%;
  align-items: center;
  justify-content: center;
  left: 0;
}
.functionStack {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
  /* left: 6%; */
}
.functionCard {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 241px;
  height: 55px;
  gap: 0.75rem;
}

.functionCardLabel {
  position: relative;
  top: -40px;
  /* border: 1px solid black; */
}
.functionCardSVG {
  position: relative;
}
.functionCardSVG:hover {
  opacity: 1 !important;
  transition: all 0.3s ease;
}
.functionCard:hover {
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.cardLook {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  display: block;
}
.cardLook:hover {
  opacity: 1 !important;
  transition: all 0.3s ease;
}
.cardCount {
  width: 10%;
  display: flex;
  align-items: center;
  opacity: 1;
}

.cardCircle {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  width: 21px;
  height: 21px;
  border-radius: 50%;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
  border: 1px solid #dddddd;
}
.folderCardsmallContainer {
  width: 400px;
  /* border: 1px solid black; */
  height: 597px;
}
.folderCard {
  margin-top: 1rem;
  width: 400px;
  height: 597px;
  display: block;
}
.folderSVG {
  position: relative;
}
.cardContent {
  position: relative;
  top: -507px;
  width: 394px;
}
.folderheader {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.fhHeader {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}
.fullViewBtn {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: right;
  text-decoration: underline;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  gap: 5px;
}
.splitter {
  width: 292px;
  position: relative;
  margin-top: 10px;
  left: 50px;
}
.fcbody {
  position: relative;
  left: 50px;
  width: 300px;
  height: 377px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  margin-top: 15px;
}
.fcbody::-webkit-scrollbar {
  width: 8px;
  color: #c0c0c0;
}
.fcbody::-webkit-scrollbar {
  width: 8px;
  height: 0px;
}
.fcbody::-webkit-scrollbar-track {
  background: #c0c0c0;
}
.fcbody::-webkit-scrollbar-thumb {
  background: #888;
}
.fcbody::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.stack {
  max-height: 400px;
  margin-bottom: 20px;
  margin-left: 5px;
  margin-right: 5px;
}
.stackhead {
  display: flex;
  flex-direction: row;
  background-color: white;
  justify-content: space-between;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  text-align: left;
  width: 15rem;
  padding: 10px;
  /* border-radius: 0 0 20px 0; */
  clip-path: polygon(100% 0%, 100% 50%, 92% 100%, 0 100%, 0% 0);
}
.stackRolecount {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid #898989;
  display: flex;
  align-items: center;
  justify-content: center;
}
.stackbody {
  background-color: white;
  margin-top: 15px;
  width: 17rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  padding: 10px;
  position: relative;
  clip-path: polygon(100% 0%, 100% 56%, 90% 100%, 0 100%, 0% 0);
}
.stackbodyHead {
  content: "";
  width: 15%;
  height: 16px;
  background-color: white;
  position: absolute;
  left: 232px;
  clip-path: polygon(100% 0, 100% 0, 100% 100%, 0 100%, 30% 0);
}

.roletag {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  padding: 3px;
}
.cardView {
  display: flex;
  flex-direction: column;
  position: relative;
  left: 31px;
}
.roleLegends {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
  position: relative;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  margin-bottom: 0.75rem;
}
.sublegends {
  display: flex;
  flex-direction: row;
  gap: 20px;
  float: right;
}
.sublegend {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
}
.backButton {
  display: flex;
  align-items: center;
}
.backButton:hover {
  cursor: pointer;
}
.backLabel {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}
.selectRole {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: black;
}
.selectRole:hover {
  color: red;
  text-decoration: underline;
  cursor: pointer;
}
